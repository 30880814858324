/*video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}*/

.container {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
} 
.container .box{
    display: block;
    align-items: center;
    text-align: center;
    position: absolute;
    padding: auto;
    margin: auto;
}

.title{
      color: #fff;
      font-size: 60px;
      font-weight: 600;
      text-align: center;
      align-items: center;
      margin: 17px;
      font-family:'Roberto';
 }
 .description {
     color: #fff;
     margin: 13px;
     font-size: 27px;
     text-align: center;
     align-items: center;
     font-weight: 450;
     font-family: Times New Roman;
 }
 .btnClass{
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 23px;
    margin-left: 83px;
}