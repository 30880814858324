.about {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
.about {
    background-image: url('../../Images/G\ Virtual\ Background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .btn_x {
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 20px;
    border: 1px solid #2e2e2e;
    outline: none;
    border-radius: 4px;
    background-color: rgb(20, 76, 230);
    color: #fff;
    margin-bottom: 50px;
  }
  .btn_:hover {
    border: 1px solid #fff;
  }
  .ContainerX {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
   
  }