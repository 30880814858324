/* @import url(https://fonts.googleapis.com/css?family=Cherry+Swash); */
.navbar {
    background: #2e4158;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    text-align: center;
  }
  
  .navbar_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 10px 20px;
      height: 100%;
      width: 100%;
      border: 1px solid #2E2E2E;
      outline: none;
      border-radius: 4px;
      background-color: #0F59FF;
      color: #fff;
  }
  .navbar_btn:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  
  .navbar-container {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    padding: auto;
    font-family: Times New Roman;
    transition: all .2s ease-out;
}
h2 {
  font-family: Times New Roman;
  display: inline-flex;
  text-align: center;
  font-weight: 969;
}
.navbar-logo:hover {
      text-decoration: none;
      color: #fff;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-items: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    
  }
  
  .nav-item {
    height: auto;
    font-family: Times New Roman;
  }
  .image {
      width: 73px;
      height: 73px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: 15px;
      background: transparent;
  }
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 1px solid #fff;
    transition: all 0.2s ease-out;
    text-decoration: none;
    color: #fff;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 1100px) {
    .NavbarItems {
      position: relative;
    }
   .navbar{
       display: flex;
       justify-content: space-around;
   }
    .nav-menu {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      height: auto;
      position: absolute;
      top: 95px;
      left: -100%;
      opacity: 1;

      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      top:0px;
      text-align: center;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #2E2E2E;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      left: 0;
      top:-10px;
      transform: translate(25%, 50%);
    }
    .navbar-logo h2{
        font-size: 23px;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .navbar_btn{
      margin-bottom: 22px;

    }
  }
  