
.login img {
  width: 100%;
  height: 90vh;
  background-size: cover;
  object-fit: cover;
}
.login {
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
  /* position: fixed; */
}

.form {
  max-width: 330px;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.11);
  padding: 20px;
  margin-top: 30px;
  font-family: sans-serif;
  position: relative;
  bottom: 520px;
  left: 50px;
  width: 300px;
  margin: 0;
}
#email {
  background: transparent;
}
.form .form__custom-button {
  margin-top: 50px;
}
input {
  background: transparent;
  color: #fff;
}
input:focus {
  background: transparent;
  color: #fff;
}
.prodcut {
  background-color: #465b6b;
  width: 1350px;
  height: 2000px;
  text-align: left;
}

.items {
  width: 300px;
  height: 470px;
  background-color: #dddddd;
}
.items img {
  width: 240px;
  height: 270px;
  position: relative;
  left: 30px;
}
.items p {
  position: relative;
  left: 30px;
  color: #3c3d3d;
}
.div0 {
  position: relative;
  border: 1px solid rgb(36, 35, 35);
  width: 300px;
  left: 30px;
  top: 30px;
}

.div1 {
  position: relative;
  left: 345px;
  bottom: 444px;
  margin: 2px;
  border: 1px solid rgb(36, 35, 35);
  width: 300px;
}
.div2 {
  position: relative;
  left: 665px;
  bottom: 917px;
  margin: 2px;
  border: 1px solid rgb(36, 35, 35);
  width: 300px;
}
.div3 {
  position: relative;
  left: 990px;
  bottom: 1390px;
  margin: 2px;
  border: 1x solid rgb(36, 35, 35);
  width: 300px;
}
.div4 {
  position: relative;
  left: 27px;
  bottom: 1370px;
  margin: 2px;
  border: 1px solid rgb(36, 35, 35);
  width: 300px;
}
