._btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 1px solid #2E2E2E;
    outline: none;
    border-radius: 4px;
    background-color: #0F59FF;
    color: #fff;
}
.left {
    text-align: center;
    justify-content: center;
    align-items: center;
}
input{
    background: transparent !important;
    color: #fff !important;
    letter-spacing: 1px !important;
}
input:focus{
    background: transparent !important;
    color: #fff !important;
    letter-spacing: 1px !important;
}
textarea {
    background: transparent !important;
    color: #fff !important;
    letter-spacing: 1px !important;
}
textarea:focus{
    background: transparent  !important;
    color: #fff !important;
    letter-spacing: 1px !important;
}
.post {
    align-items: right;
    text-align: right;
    justify-content: right;
    top:0;
    right:0px;
    margin-right: 0px;
    margin-top: auto;
}
.Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 25px;
}
input:focus {
    color: #888 !important;
}

@media screen  and (max-width:980px){
    .Container{
        display: flex;
        position: relative;
    }
}